import { Controller } from "@hotwired/stimulus";
import Cleave from "cleave.js";
import e from "local-time";

export default class extends Controller {
  static targets = ["percentage", "input"];

  initialize() {
    this.moneyFormat();
  }

  moneyFormat() {
    this.inputTargets.forEach((element) => {
      this.cleave = new Cleave(element, {
        prefix: "$",
        numeral: true,
        numeralDecimalMark: ",",
        delimiter: ".",
      });
    });
  }

  format() {
    if (this.percentageTarget.checked) {
      this.inputTargets.forEach((element) => {
        this.cleave = null;
        element.value = element.value.replace(/[^0-9.]/g, "");
        element.value = element.value + "%";
      });
    } else {
      this.moneyFormat();
    }
  }
}
