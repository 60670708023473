import { Controller } from "@hotwired/stimulus";
import { createPopper } from "@popperjs/core";

export default class extends Controller {
  static targets = ["base", "tooltip", "filter"];
  static values = {
    classToAddToBase: { type: String, default: "" },
  };

  initialize() {
    this.clickOutside = this.clickOutside.bind(this);
  }

  connect() {
    this.baseTargets.forEach((popcorn) => {
      this.tooltipTargets.forEach((tooltip) => {
        if (popcorn.id == tooltip.id) {
          createPopper(popcorn, tooltip, {
            placement: "top",
          });
        }
      });
    });

    document.addEventListener("click", this.clickOutside);
  }

  disconnect() {
    document.removeEventListener("click", this.clickOutside);
  }

  clickOutside(event) {
    // Verificar si el clic ocurrió fuera del elemento
    if (!this.element.contains(event.target)) {
      // El clic ocurrió fuera del elemento, aquí puedes realizar la acción que desees.
      this.tooltipTargets.forEach((item) => {
        item.classList.add("hidden");
      });
      if (this.classToAddToBaseValue !== null) {
        this.baseTargets.forEach((item) => {
          item.classList.remove(this.classToAddToBaseValue);
        });
      }
    }
  }

  toggleTooltip(ev) {
    this.tooltipTargets.forEach((item) => {
      if ("tooltip-".concat(ev.target.parentElement.id) == item.id) {
        item.classList.toggle("hidden");
      } else {
        item.classList.add("hidden");
      }
    });
    if (this.classToAddToBaseValue !== null) {
      this.baseTargets.forEach((item) => {
        if (ev.target.parentElement.id == item.id) {
          item.classList.toggle(this.classToAddToBaseValue);
        } else {
          item.classList.remove(this.classToAddToBaseValue);
        }
      });
    }
  }
}
