import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  connect() {
    this.handler = (event) => {
      this.load(event.detail.date, event.detail.partner);
    };
    window.addEventListener("calendar:onchange", this.handler);
  }

  disconnect() {
    window.removeEventListener("calendar:onchange", this.handler);
  }

  load(date, partner) {
    if (partner) {
      const url =
        this.data.get("url") + `/partners/${partner}/agendas?date=${date}`;

      fetch(url)
        .then((res) => res.text())
        .then((res) => {
          this.element.innerHTML = res;
        });
    }
  }
}
