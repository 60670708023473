import CheckboxSelectAll from "stimulus-checkbox-select-all";

export default class extends CheckboxSelectAll {
  static targets = ["link"];

  initialize() {
    this.toggle = this.toggle.bind(this);
    this.refresh = this.refresh.bind(this);
    this.baseUrls = {};
    this.linkTargets.forEach((link) => {
      this.baseUrls[link.id] = link.getAttribute("href");
    });
  }

  refresh() {
    const checkboxesCount = this.checkboxTargets.length;
    const checkboxesCheckedCount = this.checked.length;

    this.checkboxAllTarget.checked = checkboxesCheckedCount > 0;
    this.checkboxAllTarget.indeterminate =
      checkboxesCheckedCount > 0 && checkboxesCheckedCount < checkboxesCount;

    const event = new Event("refresh");

    this.changeIds();
  }

  toggle(e) {
    e.preventDefault();

    this.checkboxTargets.forEach((checkbox) => {
      // @ts-ignore
      checkbox.checked = e.target.checked;
      this.triggerInputEvent(checkbox);
    });

    this.changeIds();
  }

  changeIds() {
    let idList = [];
    this.checked.forEach((checkbox) => {
      idList.push(checkbox.value);
    });

    let baseUrls = this.baseUrls;

    this.linkTargets.forEach((link) => {
      let url = baseUrls[link.id];
      const idsQuery = idList.join("+");

      if (idList.length === 0) {
        link.setAttribute("href", url);
      } else {
        link.setAttribute(
          "href",
          url.includes("?")
            ? `${url}&ids=${idsQuery}`
            : `${url}?ids=${idsQuery}`
        );
      }
    });
  }

  executeController({ params: { url, method, confirmMessage, extra } }) {
    if (confirmMessage) {
      let confirmed = confirm(confirmMessage);
      if (!confirmed) {
        return;
      }
    }
    let idList = [];
    this.checked.forEach((checkbox) => {
      idList.push(checkbox.value);
    });

    fetch(url, {
      method: method,
      body: JSON.stringify({
        ids: idList,
        extra_info: extra,
      }),
      headers: {
        "content-type": "application/json",
      },
    }).then(() => {
      const url = `${window.location.pathname}`;

      window.location.href = url;
    });
  }
}
