import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["card"];
  static values = {
    canBeClosed: { type: Boolean, default: true },
    closeOnBgClick: { type: Boolean, default: true },
    closeOnSubmit: { type: Boolean, default: true },
  };

  hideModal() {
    this.element.parentElement.removeAttribute("src");
    this.element.remove();
  }

  submitEnd(e) {
    if (this.closeOnSubmitValue && e.target.id != "filter_form") {
      if (e.detail.success) {
        this.hideModal();
      }
    }
  }

  closeWithKeyboard(e) {
    if (this.canBeClosedValue) {
      if (e.code == "Escape") {
        this.hideModal();
      }
    }
  }

  closeBackground(e) {
    if (this.canBeClosedValue && this.closeOnBgClickValue) {
      if (e && this.cardTarget.contains(e.target)) {
        return;
      }
      this.hideModal();
    }
  }
}
