import { Controller } from "@hotwired/stimulus";
import Cleave from "cleave.js";

export default class extends Controller {
  initialize() {
    this.cleave = new Cleave(this.element, {
      prefix: "$",
      numeral: true,
      numeralDecimalMark: ",",
      delimiter: ".",
    });
  }
}
