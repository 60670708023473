import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  connect() {
    window.addEventListener("scroll", this.handleScroll.bind(this));
  }

  disconnect() {
    window.removeEventListener("scroll", this.handleScroll.bind(this));
  }

  scrollToTop() {
    window.scrollTo({
      top: 0,
      behavior: "smooth", // Optional: Add smooth scrolling behavior
    });
  }

  handleScroll() {
    const scrollThreshold = 120;

    if (window.scrollY > scrollThreshold) {
      this.element.classList.remove("shadow-md");
    } else {
      this.element.classList.add("shadow-md");
    }
  }
}
