import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  connect() {
    const interval = parseInt(this.data.get("interval"), 10);

    this.refreshTimer = setInterval(() => {
      this.refresh();
    }, interval);
  }

  disconnect() {
    if (this.refreshTimer) {
      clearInterval(this.refreshTimer);
    }
  }

  refresh() {
    const url = `${window.location.pathname}`;

    window.location.href = url;
  }
}
