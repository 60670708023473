import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static values = {
    targetId: String,
  };

  connect() {
    document.getElementById(this.targetIdValue).click();
    this.element.remove();
  }
}
