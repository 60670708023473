import "cleave.js/dist/addons/cleave-phone.cl.js";
import { Controller } from "@hotwired/stimulus";
import Cleave from "cleave.js";

export default class extends Controller {
  static targets = ["field"];

  initialize() {
    this.initCleave();
  }

  changeCC(ev) {
    if (ev.target.value !== "56") {
      this.cleave.destroy();
    } else {
      this.initCleave();
    }
  }

  initCleave() {
    this.cleave = new Cleave(this.fieldTarget, {
      delimiters: [" "],
      blocks: [1, 4, 4],
    });
  }
}
