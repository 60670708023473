import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["container", "slide", "prev", "next"];
  static values = {
    selectedId: String,
  };

  connect() {
    this.supportNotification = false;

    if (this.hasNotificationPermission()) {
      this.supportNotification = true;
    } else {
      Notification.requestPermission().then((result) => {
        this.supportNotification = result === "granted";
      });
    }

    window.addEventListener(
      "sendNotification",
      this.sendNotification.bind(this)
    );
  }

  hasNotificationPermission() {
    return Notification.permission === "granted";
  }

  sendNotification() {
    if (this.hasNotificationPermission()) {
      new Notification("Nueva gestión");
    }
  }
}
