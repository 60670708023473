import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["content"];
  static values = {
    closeOnSubmit: { type: Boolean, default: true },
    closeOnBgClick: { type: Boolean, default: false },
    animated: { type: Boolean, default: true },
  };

  initialize() {
    this.toggle = this.toggle.bind(this);
  }

  connect() {
    if (this.animatedValue) {
      this.isOpen = false;

      setTimeout(this.toggle);
    } else {
      this.contentTarget.classList.add("-translate-x-full");
      this.isOpen = true;
    }
  }

  toggle() {
    this.isOpen = !this.isOpen;
    if (this.isOpen) {
      this.contentTarget.classList.add("-translate-x-full");
    } else {
      this.contentTarget.classList.remove("-translate-x-full");
    }
  }

  hideModal() {
    this.removeParent = this.removeParent.bind(this);
    this.toggle();
    setTimeout(this.removeParent, 500);
  }

  removeParent() {
    this.element.parentElement.removeAttribute("src");
    this.element.remove();
  }

  submitEnd(e) {
    if (this.closeOnSubmitValue) {
      if (e.detail.success) {
        this.hideModal();
      }
    }
  }

  closeWithKeyboard(e) {
    if (e.code == "Escape") {
      this.hideModal();
    }
  }

  closeBackground(e) {
    if (
      (e && this.contentTarget.contains(e.target)) ||
      !this.closeOnBgClickValue
    ) {
      return;
    }
    this.hideModal();
  }
}
