import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static values = {
    height: String,
  };

  connect() {
    this.element.classList.add(this.heightValue);

    if (this.isOverflown(this.element)) {
      this.element.classList.add("hover:cursor-pointer", "hover:underline");
    } else {
      this.element.classList.remove(this.heightValue);
    }
  }
  toggle(ev) {
    ev.target.classList.toggle(this.heightValue);
  }

  isOverflown(element) {
    return (
      element.scrollHeight > element.clientHeight ||
      element.scrollWidth > element.clientWidth
    );
  }
}
