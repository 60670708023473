import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["field"];

  initialize() {
    this.checkInput = this.checkInput.bind(this);
  }

  connect() {
    let typingTimer; //timer identifier
    let doneTypingInterval = 500;
    this.fieldTarget.addEventListener("keyup", () => {
      clearTimeout(typingTimer);
      if (this.fieldTarget.value) {
        typingTimer = setTimeout(this.checkInput, doneTypingInterval);
      }
    });
  }

  handleInputEvent() {
    if (this.comparator()) {
      this.toggleFieldColors(true);
    }
  }

  checkInput() {
    if (this.comparator()) {
      this.toggleFieldColors(true);
    } else {
      this.toggleFieldColors(false);
    }
  }

  toggleFieldColors(boolean) {
    if (boolean) {
      this.fieldTarget.classList.remove("!border-red-600");
      this.fieldTarget.classList.add("!border-green-600");
      this.fieldTarget.classList.remove("!ring-red-600");
    } else {
      this.fieldTarget.classList.add("!border-red-600");
      this.fieldTarget.classList.add("!ring-red-600");
      this.fieldTarget.classList.remove("!border-green-600");
    }
  }

  comparator() {
    return this.compare(
      this.fieldTarget.value.length,
      this.data.get("operator"),
      this.data.get("lenght")
    );
  }

  //This is for avoid using the unsafe eval() JS function
  compare(valueA, operator, valueB) {
    switch (operator) {
      case ">":
        return valueA > valueB;
      case "<":
        return valueA < valueB;
      case ">=":
        return valueA >= valueB;
      case "<=":
        return valueA <= valueB;
      case "==":
        return valueA == valueB;
      case "!=":
        return valueA != valueB;
      case "===":
        return valueA === valueB;
      case "!==":
        return valueA !== valueB;
    }
  }
}
