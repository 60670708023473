import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["message", "specialMessage"];

  toggleMessage(ev) {
    if (["15", "8"].includes(ev.target.value)) {
      this.messageTarget.classList.remove("hidden");
      this.specialMessageTarget.classList.add("hidden");
    } else if (["18"].includes(ev.target.value)) {
      this.messageTarget.classList.add("hidden");
      this.specialMessageTarget.classList.remove("hidden");
    } else {
      this.messageTarget.classList.add("hidden");
      this.specialMessageTarget.classList.add("hidden");
    }
  }
}
