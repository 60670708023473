import { Controller } from "@hotwired/stimulus";
import { enter, leave } from "el-transition";

export default class extends Controller {
  connect() {
    enter(this.element);

    setTimeout(() => {
      leave(this.element);
      this.element.remove();
    }, 4000);
  }

  hide() {
    leave(this.element);
    this.element.remove();
  }
}
