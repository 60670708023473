import { Controller } from "@hotwired/stimulus";
import dayjs from "dayjs";
import weekday from "dayjs/plugin/weekday";
import "dayjs/locale/es";

dayjs.extend(weekday);

export default class extends Controller {
  static targets = ["date", "partner", "button"];

  initialize() {
    setTimeout(() => {
      this.onChange();
    });
  }

  onChange(date, partner) {
    date = `${dayjs(this.dateTarget.value).format(
      "YYYY-MM-DD"
    )}&no_show_create_schedule=true`;
    partner = this.partnerTarget.value;
    this.buttonTarget.value = `Reagendar para las ${dayjs(
      this.dateTarget.value
    ).format("HH:mm el DD/MM/YY")} con ${
      this.partnerTarget.options[this.partnerTarget.selectedIndex].text
    }`;
    window.dispatchEvent(
      new CustomEvent("calendar:onchange", {
        detail: { date, partner },
      })
    );
  }
}
