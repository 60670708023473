import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["button"];

  toggle(ev) {
    if (ev.target.value) {
      this.buttonTarget.disabled = false;
      this.buttonTarget.classList.remove("disabled");
    } else {
      this.buttonTarget.disabled = true;
      this.buttonTarget.classList.add("disabled");
    }
  }
}
