import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["content"];

  connect() {
    this.listener = (event) => {
      const { message, messageType, description } = event.detail;

      this.showFlashMessage(message, messageType, description);
    };

    window.addEventListener("toast:show", this.listener);
  }

  disconnect() {
    window.removeEventListener("toast:show", this.listener);
  }

  showFlashMessage(message, messageType, description) {
    fetch(`/flash_messages`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        title: message,
        description: description,
        type: messageType,
      }),
    })
      .then((response) => response.text())
      .then((html) => Turbo.renderStreamMessage(html));
  }
}
