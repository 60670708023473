import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["submit"];
  static values = {
    timer: { type: Number, default: 60000 },
  };

  connect() {
    this.refreshTimer = setInterval(() => {
      this.clickSubmit(this.submitTarget);
    }, this.timerValue);
  }

  disconnect() {
    if (this.refreshTimer) {
      clearInterval(this.refreshTimer);
    }
  }

  clickSubmit(element) {
    element.click();
  }

  save() {
    this.submitTarget.click();
  }
}
