import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["digit", "submit", "loading", "pinNumber"];

  handleInput(event) {
    const input = event.target;
    const value = input.value;

    // Asegurarse de que solo se acepten números
    if (!/^\d$/.test(value)) {
      input.value = "";
      return;
    }

    // Enfocar al siguiente cuadro de entrada
    const nextIndex = this.digitTargets.indexOf(input) + 1;
    if (nextIndex < this.digitTargets.length) {
      this.digitTargets[nextIndex].focus();
    }

    this.checkIfComplete();
  }

  handleKeydown(event) {
    const input = event.target;

    // Manejar la tecla de borrar (backspace)
    if (event.key === "Backspace") {
      this.submitTarget.disabled = true;
      if (input.value === "") {
        const prevIndex = this.digitTargets.indexOf(input) - 1;
        if (prevIndex >= 0) {
          this.digitTargets[prevIndex].focus();
        }
      }
    }
  }

  checkIfComplete() {
    const allFilled = this.digitTargets.every((input) =>
      input.value.match(/^\d$/)
    );
    this.submitTarget.disabled = !allFilled;
  }

  disableSubmitButton() {
    this.submitTarget.disabled = true;
    this.submitTarget.hidden = true;
    this.submitTarget.classList.add("!hidden");
    this.loadingTarget.classList.remove("!hidden");
    var pin_number = "";
    this.digitTargets.forEach((input) => {
      input.disabled = true;
      pin_number += input.value;
    });
    this.pinNumberTarget.value = pin_number;
  }
}
