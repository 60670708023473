import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["reasonInput", "mentalHealthAlert", "alertText"];

  connect() {
    console.log("MentalHealthController connected");
    this.loadKeywords();
    this.checkKeywords = this.checkKeywords.bind(this);
    this.reasonInputTarget.addEventListener("input", this.checkKeywords);
  }

  disconnect() {
    this.reasonInputTarget.removeEventListener("input", this.checkKeywords);
  }

  async loadKeywords() {
    try {
      const response = await fetch("/json/mental_health_keywords.json");
      const data = await response.json();
      this.mentalHealthKeywords = data.mental_health_keywords.map(
        this.normalizeText
      );
    } catch (error) {
      console.error("Error loading keywords:", error);
    }
  }

  normalizeText(text) {
    return text
      .normalize("NFD")
      .replace(/[\u0300-\u036f]/g, "")
      .toLowerCase();
  }

  checkKeywords() {
    const inputText = this.normalizeText(this.reasonInputTarget.value);
    let keywordFound = null;

    this.mentalHealthKeywords.forEach((keyword) => {
      if (inputText.includes(keyword) && !keywordFound) {
        keywordFound = keyword;
      }
    });

    if (keywordFound) {
      this.alertTextTarget.innerHTML = this.alertTextTarget.innerHTML.replace(
        "....",
        `<strong>${keywordFound}</strong>`
      );
      this.mentalHealthAlertTarget.classList.remove("hidden");
    }
  }
}
