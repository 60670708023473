import { Controller } from "@hotwired/stimulus";
import { createPopper } from "@popperjs/core";

export default class extends Controller {
  static targets = ["input", "results", "form"];
  typingTimeout = null;

  static values = {
    animated: { type: Boolean, default: false },
    phrases: { type: String, default: "" },
  };

  connect() {
    this.popper = createPopper(this.inputTarget, this.resultsTarget, {
      placement: "bottom-start",
    });

    this.inputTarget.addEventListener("input", this.onChange.bind(this));
    window.addEventListener("click", this.listenClickOutside.bind(this));

    window.addEventListener("search:clear", this.clearResults.bind(this));

    if (this.animatedValue) {
      this.startTyping();
    }
  }

  disconnect() {
    this.inputTarget.removeEventListener("input", this.onChange.bind(this));
    window.removeEventListener("click", this.listenClickOutside.bind(this));
    clearTimeout(this.typingTimeout);
  }

  onChange(ev) {
    const { value } = ev.target;

    if (value && value.length > 2) {
      clearTimeout(this.timerId);

      this.timerId = setTimeout(() => {
        this.showResults();
        this.formTarget.requestSubmit();
      }, 300);
    } else if (value == "") {
      this.hideResults();
    }
  }

  listenClickOutside(ev) {
    if (this.element !== ev.target && !this.element.contains(ev.target)) {
      this.resultsTarget.classList.add("hidden");
    } else {
      if (this.animatedValue) {
        clearTimeout(this.typingTimeout);
        this.inputTarget.value = "";
        this.animatedValue = false;
      }
    }
  }

  showResults() {
    this.resultsTarget.classList.remove("hidden");
    this.popper.update();
  }

  hideResults() {
    this.resultsTarget.classList.add("hidden");
  }

  clearResults() {
    this.resultsTarget.classList.add("hidden");
    this.inputTarget.value = "";
  }

  setResult(event) {
    this.resultsTarget.classList.add("hidden");
    const nameElement = event.currentTarget.querySelector("#name");
    if (nameElement) {
      this.inputTarget.value = nameElement.textContent;
    }
  }

  startTyping() {
    const phrases = this.phrasesValue.split(",");

    let index = 0;

    const typeNextPhrase = () => {
      const phrase = phrases[index];
      const inputTarget = this.inputTarget;

      let charIndex = 0;

      const typeCharacter = () => {
        inputTarget.value = phrase.slice(0, charIndex);
        charIndex++;

        if (charIndex <= phrase.length) {
          this.typingTimeout = setTimeout(typeCharacter, 60); // Adjust typing speed
        } else {
          index = (index + 1) % phrases.length;
          this.typingTimeout = setTimeout(typeNextPhrase, 1000); // Wait til next phrase
        }
      };

      typeCharacter();
    };

    typeNextPhrase();
  }
}
