import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["writingButton", "videoButton", "videocall", "form"];
  static values = { mobile: Boolean };

  writingMode() {
    this.formTarget.classList.add("w-8/12");
    this.formTarget.classList.remove("w-4/12");

    this.videocallTarget.classList.add("hidden");

    this.writingButtonTarget.classList.add("!hidden");

    this.videoButtonTarget.classList.remove("!hidden");
  }

  videoMode() {
    this.formTarget.classList.remove("w-8/12");
    this.formTarget.classList.add("w-4/12");

    this.videocallTarget.classList.remove("hidden");

    this.videoButtonTarget.classList.add("!hidden");

    this.writingButtonTarget.classList.remove("!hidden");
  }
}
