import { Controller } from "@hotwired/stimulus";
import { createPopper } from "@popperjs/core";

export default class extends Controller {
  static targets = ["button", "container"];
  connect() {
    this.popper = createPopper(this.buttonTarget, this.containerTarget, {
      placement: "bottom-end",
    });

    this.listener = (ev) => {
      if (this.element !== ev.target && !this.element.contains(ev.target)) {
        this.containerTarget.classList.add("hidden");
      }
    };

    window.addEventListener("click", this.listener);
  }

  disconnect() {
    window.removeEventListener("click", this.listener);
  }

  toggle() {
    this.containerTarget.classList.toggle("hidden");
    this.popper.update();
  }
}
