import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static values = {
    mockUpLink: { type: Boolean, default: false },
  };

  connect() {
    if (this.mockUpLinkValue) {
      this.element.classList.remove("!hidden");
    } else {
      this.element.classList.add("!hidden");
    }
  }
}
