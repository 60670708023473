import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["popup"];

  mouse() {
    this.popupTarget.hidden = false;
  }

  out() {
    this.popupTarget.hidden = true;
  }
}
