import { Controller } from "@hotwired/stimulus";
import ahoy from "ahoy.js";

export default class extends Controller {
  static values = {
    name: { default: "", type: String },
    properties: { default: "{}", type: String },
  };

  connect() {
    this.element.addEventListener("click", (ev) => {
      ahoy.track(this.nameValue, JSON.parse(this.propertiesValue));
    });
  }
}
