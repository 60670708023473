// file_upload_controller.js
import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["input", "previewContainer"];

  previewFiles(event) {
    const input = event.target;
    const files = input.files;
    this.previewContainerTarget.innerHTML = ""; // Clear previous previews

    if (files) {
      Array.from(files).forEach((file) => this.readFile(file));
    }
  }

  readFile(file) {
    const reader = new FileReader();
    reader.onload = (event) => this.displayPreview(event, file);
    reader.readAsDataURL(file);
  }

  displayPreview(event, file) {
    const src = event.target.result;
    const previewElement = document.createElement("div");
    previewElement.classList.add("preview");

    if (file.type.startsWith("image/")) {
      const imgContainer = document.createElement("div");
      imgContainer.classList.add("relative", "inline-block");

      const img = document.createElement("img");
      img.src = src;
      img.classList.add("w-16", "h-16", "object-cover");
      imgContainer.appendChild(img);

      const deleteButton = document.createElement("button");
      deleteButton.classList.add(
        "absolute",
        "-top-3",
        "-right-3",
        "z-10",
        "bg-transparent",
        "border-none",
        "cursor-pointer"
      );
      deleteButton.innerHTML =
        '<svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="1"><path stroke-linecap="round" stroke-linejoin="round" d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16" /></svg>';
      deleteButton.dataset.action = "click->file-upload#removePreview";
      imgContainer.appendChild(deleteButton);

      previewElement.appendChild(imgContainer);
    } else {
      const fileInfo = document.createElement("p");
      fileInfo.textContent = file.name;
      fileInfo.classList.add("file-info");
      previewElement.appendChild(fileInfo);
    }

    this.previewContainerTarget.appendChild(previewElement);
  }
  removePreview(event) {
    event.target.closest(".preview").remove();
  }
}
