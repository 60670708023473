import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["tab"];

  connect() {
    this.focusElement = (ev) => {
      this.tabTargets.forEach((item) => {
        item.classList.add(
          "text-gray-500",
          "border-white",
          "hover:border-gray-200"
        );
        item.classList.remove("border-primary", "text-gray-900");
      });
      ev.target.classList.remove(
        "text-gray-500",
        "border-white",
        "hover:border-gray-200"
      );
      ev.target.classList.add("border-primary", "text-gray-900");
    };

    this.element.addEventListener("turbo:click", this.focusElement);
  }
}
