import Litepicker from "litepicker";
import "litepicker/dist/plugins/mobilefriendly";

import { Controller } from "@hotwired/stimulus";
import dayjs from "dayjs";

export default class extends Controller {
  static targets = ["datepicker", "start", "end"];
  static values = {
    format: { type: String, default: "DD-MM-YYYY" },
  };

  initialize() {
    console.log(this.formatValue);
    new Litepicker({
      element: this.datepickerTarget,
      autoApply: true,
      singleMode: false,
      numberOfColumns: 2,
      numberOfMonths: 2,
      lang: "es",
      format: this.formatValue,
      tooltipText: {
        one: "día",
        other: "días",
      },
      startDate: this.startTarget.value,
      endDate: this.endTarget.value,
      plugins: ["mobilefriendly"],
      setup: (picker) => {
        picker.on("show", () => {
          let date = picker.getDate();
          if (date) {
            date.setMonth(date.getMonth() - 1);
            picker.gotoDate(date);
          }
        });
        picker.on("preselect", (date1, date2) => {
          if (date1 && date2) {
            this.startTarget.value = dayjs(date1.dateInstance).format(
              this.formatValue
            );
            this.endTarget.value = dayjs(date2.dateInstance).format(
              this.formatValue
            );

            window.dispatchEvent(new CustomEvent("litepicker:onchange"));
          }
        });
      },
    });
  }
}
