import { Controller } from "@hotwired/stimulus";
import TomSelect from "tom-select";

export default class extends Controller {
  static values = {
    type: String,
    autoscroll: { type: Boolean, default: false },
  };
  connect() {
    const params =
      this.typeValue == "dropdown"
        ? {
            plugins: ["dropdown_input"],
            allowEmptyOption: true,
          }
        : {
            plugins: {
              remove_button: {},
            },
            maxOptions: null,
          };

    new TomSelect(this.element, params);

    if (this.autoscrollValue) {
      const dropdown =
        this.element.parentElement.getElementsByClassName("dropdown-input")[0];
      dropdown.addEventListener("focus", () => {
        dropdown.scrollIntoView({ behavior: "smooth", block: "start" });
      });
    }
  }
}
