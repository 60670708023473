import { Controller } from "@hotwired/stimulus";
import Cleave from "cleave.js";

export default class extends Controller {
  initialize() {
    this.cleave = new Cleave(this.element, {
      numeral: true,
      numeralPositiveOnly: true,
      numeralDecimalMark: "-",
      delimiter: "",
      numeralDecimalScale: 4,
    });
  }
}
