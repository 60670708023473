import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = [
    "submit",
    "field",
    "confirmationField",
    "lengthCircle",
    "lengthTicket",
    "lengthX",
    "numberCircle",
    "numberTicket",
    "numberX",
    "specialCircle",
    "specialTicket",
    "specialX",
    "showBtn",
    "hideBtn",
    "matchesCircle",
    "matchesTicket",
    "matchesX",
  ];
  static values = {
    minLength: { type: Number, default: 8 },
    lenghtCheck: { type: Boolean, default: false },
    numberCheck: { type: Boolean, default: false },
    specialCheck: { type: Boolean, default: false },
    passwordConfirmation: { type: Boolean, default: false },
  };

  connect() {
    this.submitTarget.disabled = true;
  }

  handleInputEvent() {
    this.hideCircles();
    this.checkConditions();
    //Check length
    this.toggleView(
      this.lenghtCheckValue,
      this.lengthXTarget,
      this.lengthTicketTarget
    );
    //Check number char
    this.toggleView(
      this.numberCheckValue,
      this.numberXTarget,
      this.numberTicketTarget
    );
    //Check special char
    this.toggleView(
      this.specialCheckValue,
      this.specialXTarget,
      this.specialTicketTarget
    );
  }

  checkConditions() {
    //Check length
    this.lenghtCheckValue =
      this.fieldTarget.value.length >= this.minLengthValue;
    //Check number char
    this.numberCheckValue = /\d/.test(this.fieldTarget.value);
    //Check special char
    this.specialCheckValue = /[ `!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/.test(
      this.fieldTarget.value
    );

    //Enable submit if all conditions are true
    if (this.passwordConfirmationValue) {
      this.twoPasswordChecker();
    } else {
      this.onePasswordChecker();
    }
  }

  onePasswordChecker() {
    if (
      this.lenghtCheckValue &&
      this.numberCheckValue &&
      this.specialCheckValue
    ) {
      this.submitTarget.disabled = false;
    } else {
      this.submitTarget.disabled = true;
    }
  }

  twoPasswordChecker() {
    if (
      this.lenghtCheckValue &&
      this.numberCheckValue &&
      this.specialCheckValue &&
      this.confirmationFieldTarget.value == this.fieldTarget.value
    ) {
      this.submitTarget.disabled = false;
    } else {
      this.submitTarget.disabled = true;
    }
  }

  toggleView(boolean, x_mark, ticket) {
    if (boolean) {
      x_mark.classList.add("hidden");
      ticket.classList.remove("hidden");
    } else {
      x_mark.classList.remove("hidden");
      ticket.classList.add("hidden");
    }
  }

  toggleField() {
    this.showBtnTarget.classList.toggle("hidden");
    this.hideBtnTarget.classList.toggle("hidden");
    if (this.showBtnTarget.classList.contains("hidden")) {
      this.fieldTarget.type = "text";
    } else {
      this.fieldTarget.type = "password";
    }
  }

  hideCircles() {
    this.lengthCircleTarget.classList.add("hidden");
    this.numberCircleTarget.classList.add("hidden");
    this.specialCircleTarget.classList.add("hidden");
  }

  passwordMatches() {
    this.matchesCircleTarget.classList.add("hidden");

    if (this.confirmationFieldTarget.value == this.fieldTarget.value) {
      this.submitTarget.disabled = false;
    } else {
      this.submitTarget.disabled = true;
    }

    this.toggleView(
      this.confirmationFieldTarget.value == this.fieldTarget.value,
      this.matchesXTarget,
      this.matchesTicketTarget
    );

    this.checkConditions();
  }
}
