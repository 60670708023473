import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = [
    "checkField",
    "matchX",
    "matchTicket",
    "submit",
    "field",
    "lengthCircle",
    "lengthTicket",
    "lengthX",
    "numberCircle",
    "numberTicket",
    "numberX",
    "specialCircle",
    "specialTicket",
    "specialX",
  ];
  static values = {
    minLength: { type: Number, default: 8 },
    lenghtCheck: { type: Boolean, default: false },
    numberCheck: { type: Boolean, default: false },
    specialCheck: { type: Boolean, default: false },
    matchCheck: { type: Boolean, default: false },
  };

  connect() {
    this.submitTarget.disabled = true;
  }

  handleInputEvent() {
    this.hideCircles();
    this.checkConditions();
    //Check length
    this.toggleView(
      this.lenghtCheckValue,
      this.lengthXTarget,
      this.lengthTicketTarget
    );
    //Check number char
    this.toggleView(
      this.numberCheckValue,
      this.numberXTarget,
      this.numberTicketTarget
    );
    //Check special char
    this.toggleView(
      this.specialCheckValue,
      this.specialXTarget,
      this.specialTicketTarget
    );

    this.toggleView(
      this.matchCheckValue,
      this.matchXTarget,
      this.matchTicketTarget
    );
  }

  checkConditions() {
    //Check length
    this.lenghtCheckValue =
      this.fieldTarget.value.length >= this.minLengthValue;
    //Check number char
    this.numberCheckValue = /\d/.test(this.fieldTarget.value);
    //Check special char
    this.specialCheckValue = /[ `!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/.test(
      this.fieldTarget.value
    );

    this.matchCheckValue =
      this.fieldTarget.value == this.checkFieldTarget.value;

    //Enable submit if all conditions are true
    if (
      this.lenghtCheckValue &&
      this.numberCheckValue &&
      this.specialCheckValue &&
      this.matchCheckValue
    ) {
      this.submitTarget.disabled = false;
    } else {
      this.submitTarget.disabled = true;
    }
  }

  toggleView(boolean, x_mark, ticket) {
    if (boolean) {
      x_mark.classList.add("hidden");
      ticket.classList.remove("hidden");
    } else {
      x_mark.classList.remove("hidden");
      ticket.classList.add("hidden");
    }
  }

  hideCircles() {
    this.lengthCircleTarget.classList.add("hidden");
    this.numberCircleTarget.classList.add("hidden");
    this.specialCircleTarget.classList.add("hidden");
  }
}
