import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["overlay", "container"];

  show() {
    this.overlayTarget.classList.remove("hidden");
    this.containerTarget.classList.remove("translate-x-full");
  }

  hide() {
    this.overlayTarget.classList.add("hidden");
    this.containerTarget.classList.add("translate-x-full");
  }
}
