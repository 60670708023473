import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["input", "output", "placeholder", "submit"];

  readUrl() {
    const image = this.outputTarget;
    const file = this.inputTarget.files[0];

    if (file.type.startsWith("image/")) {
      const reader = new FileReader();

      reader.onload = (ev) => {
        image.setAttribute("src", ev.target.result);
        image.setAttribute("alt", file.name);
        image.classList.remove("hidden");
      };

      this.submitTarget.disabled = false;
      reader.readAsDataURL(file); // convert to base64 string
    } else {
      const text = document.createTextNode(file.name);
      image.parentNode.insertBefore(text, image);
      image.parentNode.removeChild(image);
    }

    this.placeholderTargets.forEach((el) => {
      el.classList.add("hidden");
    });
  }

  openDialog() {
    this.inputTarget.click();
  }
}
