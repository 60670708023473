import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["more", "less", "content"];

  connect() {
    this.moreTarget.addEventListener("click", (ev) => {
      this.moreTarget.classList.add("hidden");
      this.lessTarget.classList.remove("hidden");
      this.contentTarget.classList.remove("max-h-20");
      this.contentTarget.classList.add("max-h-96");
    });

    this.lessTarget.addEventListener("click", (ev) => {
      this.moreTarget.classList.remove("hidden");
      this.lessTarget.classList.add("hidden");
      this.contentTarget.classList.add("max-h-20");
      this.contentTarget.classList.remove("max-h-96");
    });
  }
}
