import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  connect() {
    // Get the iframe element via javascript
    const iframe = document.getElementById("iframeMeet");

    // Emit the event to the iframe
    iframe.contentWindow.postMessage({ type: "hangup" }, "*");

    const event = new CustomEvent("hangup-meeting");
    window.dispatchEvent(event);
  }
}
