import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["content", "loader"];
  static values = {
    partnerId: String,
    calendarId: String,
  };

  connect() {
    this.handler = (event) => {
      this.load(event.detail.date);
    };
    if (this.partnerIdValue) {
      this.calendarIdValue = `calendar_${this.partnerIdValue}`;
    } else {
      this.calendarIdValue = "calendar";
    }
    window.addEventListener(`${this.calendarIdValue}:onchange`, this.handler);
  }

  disconnect() {
    window.removeEventListener(
      `${this.calendarIdValue}:onchange`,
      this.handler
    );
  }

  load(date) {
    let url = "";
    if (this.data.get("url").includes("?")) {
      url = this.data.get("url") + `&date=${date}`;
    } else {
      url = this.data.get("url") + `?date=${date}`;
    }

    this.contentTarget.classList.add("hidden");
    this.loaderTarget.classList.remove("hidden");

    fetch(url)
      .then((res) => res.text())
      .then((res) => {
        this.contentTarget.innerHTML = res;
        this.contentTarget.classList.remove("hidden");
        this.loaderTarget.classList.add("hidden");
      });
  }
}
