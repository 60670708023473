import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["documentType"];

  formatValue(value) {
    if (Number.isNaN(value)) return "";
    if (!value) return "";
    const rut = value.replace(/^0+|[^0-9kK]+/g, "").toUpperCase();
    let result = `${rut.slice(-4, -1)}-${rut.substr(rut.length - 1)}`;
    for (let i = 4; i < rut.length; i += 3) {
      result = `${rut.slice(-3 - i, -i)}.${result}`;
    }
    return result;
  }

  handleInputEvent({ target }) {
    if (
      this.documentTypeTarget.options[this.documentTypeTarget.selectedIndex]
        .text === "RUT"
    )
      target.value = this.formatValue(target.value);
  }
}
