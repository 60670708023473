import { Controller } from "@hotwired/stimulus";
import { MediaPermissionsErrorType, requestMediaPermissions } from "mic-check";

export default class extends Controller {
  static targets = ["status", "block", "blockByOther", "modal", "banner"];
  static values = { mobile: Boolean };

  connect() {
    requestMediaPermissions()
      .then(() => {
        this.element.classList.remove("hidden");
        this.modalTarget.classList.add("hidden");
        var lang = navigator.language || navigator.userLanguage;
        if (!lang.includes("es")) {
          this.bannerTarget.classList.remove("hidden");
        }
      })
      .catch((err) => {
        if (!this.mobileValue) {
          this.element.classList.remove("hidden");
        }
        const { type, name, message } = err;
        this.statusTarget.classList.add("hidden");
        if (type === MediaPermissionsErrorType.SystemPermissionDenied) {
          // browser does not have permission to access camera or microphone
          this.blockTarget.classList.remove("hidden");
        } else if (type === MediaPermissionsErrorType.UserPermissionDenied) {
          // user didn't allow app to access camera or microphone
          this.blockTarget.classList.remove("hidden");
        } else if (
          type === MediaPermissionsErrorType.CouldNotStartVideoSource
        ) {
          // camera is in use by another application (Zoom, Skype) or browser tab (Google Meet, Messenger Video)
          // (mostly Windows specific problem)
          this.blockTarget.classList.remove("hidden");
        } else if (message == "Permission dismissed") {
          // Permission modal close
          this.blockTarget.classList.remove("hidden");
        } else {
          ("Imposible conectar con cámara y micrófono");
          console.log(err);
        }
      });
  }

  reload() {
    window.location.reload();
  }
}
