import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["messages", "files"];

  toggle(ev) {
    for (const message of this.messagesTarget.children) {
      if (message.id == ev.target.value) {
        message.classList.remove("hidden");
      } else {
        message.classList.add("hidden");
      }
    }
    for (const message of this.filesTarget.children) {
      if (message.id == `${ev.target.value}_message`) {
        message.classList.remove("hidden");
      } else {
        message.classList.add("hidden");
      }
    }
  }
}
