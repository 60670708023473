import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["submit"];
  static values = {
    limit: { type: Number, default: 6 },
  };

  runCheck(ev) {
    const disabled = ev.target.value.length < this.limitValue;

    this.submitTarget.disabled = disabled;
  }
}
