import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  formatValue(value) {
    if (Number.isNaN(value)) return "";
    if (!value) return "";

    let rut = value.replace(/^0+|[^0-9kK]+/g, "").toUpperCase();

    if (rut.length > 9) {
      rut = rut.substring(0, 9);
    }

    let formattedValue = rut.replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    return formattedValue;
  }

  handleInputEvent({ target }) {
    target.value = this.formatValue(target.value);
  }
}
