import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static values = {
    interval: { type: Number, default: 750 },
    url: { type: String, default: "" },
  };

  connect() {
    const interval = this.intervalValue;

    this.refreshTimer = setTimeout(() => {
      Turbo.visit(this.urlValue, { frame: this.element.parentElement.id });
    }, interval);
  }

  disconnect() {
    if (this.refreshTimer) {
      clearInterval(this.refreshTimer);
    }
  }
}
