import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  open() {
    if (window.HubSpotConversations) {
      window.HubSpotConversations.widget.open();
    } else {
      window.hsConversationsOnReady = [
        () => {
          window.HubSpotConversations.widget.open();
        },
      ];
    }
  }
}
