import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["view", "content", "pointingOut", "pointingIn"];

  connect() {
    window.addEventListener("hangup-meeting", this.hideView.bind(this));
  }

  disconnect() {
    window.removeEventListener("hangup-meeting", this.hideView.bind(this));
  }

  hideView() {
    this.contentTarget.classList.add("hidden");
  }

  maximice() {
    this.pointingOutTarget.classList.add("hidden");
    this.pointingInTarget.classList.remove("hidden");
    this.viewTarget.classList.remove("md:w-4/12");
    this.viewTarget.classList.add(
      "w-full",
      "h-screen",
      "absolute",
      "top-0",
      "right-0",
      "z-100"
    );
  }

  minimice() {
    this.pointingOutTarget.classList.remove("hidden");
    this.pointingInTarget.classList.add("hidden");
    this.viewTarget.classList.add("md:w-4/12");
    this.viewTarget.classList.remove(
      "w-full",
      "h-screen",
      "absolute",
      "top-0",
      "right-0",
      "z-100"
    );
  }
}
