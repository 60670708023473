import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["input", "placeholder", "submit", "spinner"];

  readUrl(event) {
    const fileList = event.target.files;

    this.placeholderTarget.classList.add("hidden");
    this.spinnerTarget.classList.remove("hidden");

    if (fileList.length !== 0) {
      this.submitTarget.click();
    }
  }

  openDialog() {
    this.inputTarget.click();
  }
}
