import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["container", "hideBtn", "showBtn", "body", "sideMenu"];
  static values = {
    mobile: { type: Boolean, default: false },
  };

  show() {
    this.hideBtnTarget.classList.remove("hidden");
    this.showBtnTarget.classList.add("hidden");

    if (this.mobileValue) {
      this.containerTarget.classList.remove("hidden");
    } else {
      this.containerTarget.classList.remove("md:-translate-x-48");
      this.containerTarget.classList.add("md:translate-x-0");
      this.bodyTarget.classList.remove("md:-translate-x-48");
      this.sideMenuTarget.classList.remove("hidden");
    }
  }

  hide() {
    this.hideBtnTarget.classList.add("hidden");
    this.showBtnTarget.classList.remove("hidden");

    if (this.mobileValue) {
      this.containerTarget.classList.add("hidden");
    } else {
      this.containerTarget.classList.add("md:-translate-x-48");
      this.containerTarget.classList.remove("md:translate-x-0");
      this.bodyTarget.classList.add("md:-translate-x-48");
      this.sideMenuTarget.classList.add("hidden");
    }
  }
}
