import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["spinner", "switch"];
  static values = {
    clicked: { type: Boolean, default: false },
  };

  submit(event) {
    this.switchTargets.forEach((item) => {
      item.classList.add("hidden");
    });
    this.spinnerTarget.classList.remove("hidden");
    if (!this.clickedValue) {
      this.clickedValue = true;
    } else {
      event.preventDefault();
    }
  }
}
