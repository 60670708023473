import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["base", "tooltip", "filter"];

  initialize() {
    this.clickOutside = this.clickOutside.bind(this);
  }

  connect() {
    document.addEventListener("click", this.clickOutside);
  }

  disconnect() {
    document.removeEventListener("click", this.clickOutside);
  }

  toggleTooltip(ev) {
    this.tooltipTargets.forEach((item) => {
      if (ev.target.parentElement.id == item.id) {
        item.classList.toggle("hidden");
      } else {
        item.classList.add("hidden");
      }
    });
    this.baseTargets.forEach((item) => {
      if (ev.target.parentElement.id == item.id) {
        item.classList.toggle("drop-shadow");
      } else {
        item.classList.remove("drop-shadow");
      }
    });
  }

  disableBases() {
    const filterList = [];
    this.filterTargets.forEach((filter) => {
      if (filter.checked) {
        if (!filterList.includes(filter.value)) {
          filterList.push(JSON.parse(filter.value));
        }
      }
    });

    this.baseTargets.forEach((base) => {
      var serviceIsPresent = false;
      JSON.parse(base.getAttribute("data-services")).forEach((service) => {
        if (filterList.includes(service)) {
          serviceIsPresent = true;
        }
      });
      if (!serviceIsPresent) {
        base.classList.add("bg-gray-200", "text-gray-600");
        base.classList.remove(
          "bg-blue-200",
          "border-blue-300",
          "text-blue-800"
        );
      } else {
        base.classList.remove("bg-gray-200", "text-gray-600");
        base.classList.add("bg-blue-200", "border-blue-300", "text-blue-800");
      }
    });
  }

  clickOutside(event) {
    var clickOnElement = false;
    this.tooltipTargets.forEach((item) => {
      if (event.target.parentElement.id == item.id) {
        clickOnElement = true;
      }
    });
    this.baseTargets.forEach((item) => {
      if (event.target.parentElement.id == item.id) {
        clickOnElement = true;
      }
    });

    if (!clickOnElement) {
      this.toggleTooltip(event);
    }
  }
}
