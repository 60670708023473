import { Controller } from "@hotwired/stimulus";
import { createPopper } from "@popperjs/core";

export default class extends Controller {
  static targets = ["tooltip"];

  connect() {
    this.popper = createPopper(this.element, this.tooltipTarget, {
      placement: "top",
    });

    this.enter = () => {
      this.tooltipTarget.classList.remove("hidden");
      this.popper.update();
    };

    this.exit = () => {
      this.tooltipTarget.classList.add("hidden");
    };

    this.element.addEventListener("mouseover", this.enter);
    this.element.addEventListener("mouseout", this.exit);
  }

  disconnect() {
    this.element.removeEventListener("mouseover", this.enter);
    this.element.removeEventListener("mouseout", this.exit);
  }
}
