import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["filter"];
  static values = { actionToListen: String };

  initialize() {
    const urlSearchParams = new URLSearchParams(window.location.search);
    this.query = Object.fromEntries(urlSearchParams.entries());
  }

  connect() {
    this.handler = (event) => {
      const { name, value } = event.detail;

      this.query[name] = value;
      this.apply();
    };
    window.addEventListener("filter:apply", this.handler);
    if (this.actionToListenValue) {
      window.addEventListener(this.actionToListenValue, this.filter.bind(this));
    }
  }

  disconnect() {
    window.removeEventListener("filter:apply", this.handler);
  }

  filter() {
    console.log(this.filterTargets);
    this.filterTargets
      // .filter((t) => t.getAttribute("data-filter-value") || t.value)
      .forEach((t) => {
        const name = t.getAttribute("data-filter-name") || t.name;
        const value = t.getAttribute("data-filter-value") || t.value;

        if (value) {
          this.query[name] = value;
        } else {
          delete this.query[name];
        }
      });

    this.apply();
  }

  apply() {
    const params = new URLSearchParams(this.query);
    const url = new URL(window.location);

    url.search = params;

    Turbo.visit(url.toString());
  }

  clear({ target }) {
    const name = target.getAttribute("data-filter-name") || target.name;

    if (name === "date_range") {
      delete this.query["start_date"];
      delete this.query["end_date"];
    } else {
      delete this.query[name];
    }

    this.apply();
  }
}
