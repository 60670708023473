import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["cash", "credits", "mixed",  "refund", "selected"];

  connect() {
    this.changeStatus(this.refundTarget.value);
  }

  toggleMessage(ev) {
    this.changeStatus(ev.target.value);
  }

  changeStatus(value) {
    if (value == "cash") {
      this.cashTarget.classList.remove("hidden");
      this.creditsTarget.classList.add("hidden");
      this.selectedTarget.classList.add("hidden");
    } else if (value == "credits") {
      this.cashTarget.classList.add("hidden");
      this.creditsTarget.classList.remove("hidden");
      this.selectedTarget.classList.add("hidden");
    } else if (value == "mixed") {
      this.cashTarget.classList.add("hidden");
      this.creditsTarget.classList.add("hidden");
      this.selectedTarget.classList.add("hidden");
      this.mixedTarget.classList.remove("hidden");
    } else {
      this.cashTarget.classList.add("hidden");
      this.creditsTarget.classList.add("hidden");
      this.mixedTarget.classList.add("hidden");
      this.selectedTarget.classList.remove("hidden");
    }
  }
}
