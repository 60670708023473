import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  connect() {
    this.load();
  }

  load() {
    const url = this.data.get("url");

    fetch(url)
      .then((res) => res.text())
      .then((res) => {
        this.element.innerHTML = res;
      });
  }
}
