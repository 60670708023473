import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static values = {
    message: { type: String, default: "" },
    type: { type: String, default: "success" },
    description: { type: String, default: "" },
  };

  connect() {
    const event = new CustomEvent("toast:show", {
      detail: {
        message: this.messageValue,
        messageType: this.typeValue,
        description: this.descriptionValue,
      },
    });

    window.dispatchEvent(event);

    let url = new URL(location.href);

    const params = new URLSearchParams(url.searchParams);

    window.history.replaceState(
      {},
      document.title,
      window.location.pathname + "?" + params.toString()
    );
  }
}
