import { Controller } from "@hotwired/stimulus";
import dayjs from "dayjs";
import weekday from "dayjs/plugin/weekday";
import "dayjs/locale/es";

dayjs.extend(weekday);

export default class extends Controller {
  static targets = ["source"];

  initialize() {
    setTimeout(() => {
      this.onChange();
    });
  }

  onChange(date) {
    date = dayjs(this.sourceTarget.value).format("YYYY-MM-DD");
    window.dispatchEvent(
      new CustomEvent("calendar:onchange", {
        detail: { date },
      })
    );
  }
}
