import { Controller } from "@hotwired/stimulus";
import { Turbo } from "@hotwired/turbo-rails";

export default class extends Controller {
  static targets = ["column"];
  static values = {
    url: String,
  };

  connect() {
    this.element.addEventListener("dragstart", this.dragstart.bind(this));
    this.columnTargets.forEach((column) => {
      column.addEventListener("dragover", this.dragover.bind(this));
      column.addEventListener("drop", this.drop.bind(this));
    });
  }

  dragstart(event) {
    this.elementToMoveID = event.target.dataset.dragId;
  }

  dragover(event) {
    event.preventDefault();
  }

  drop(event) {
    event.preventDefault();
    const targetColumn = event.target.closest(
      '[data-drag-and-drop-target="column"]'
    );
    const response = fetch(`/${this.urlValue}/${this.elementToMoveID}`, {
      method: "PATCH",
      headers: {
        "Content-Type": "application/json",
        "X-CSRF-Token": document
          .querySelector('meta[name="csrf-token"]')
          .getAttribute("content"),
      },
      body: JSON.stringify({
        procedure: { status: targetColumn.dataset.column },
      }),
    });
    if (response.ok) {
      Turbo.visit(window.location.href, { action: "replace" });
    }
  }
}
