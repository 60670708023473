import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["container", "slide", "prev", "next"];
  static values = {
    selectedId: String,
  };

  connect() {
    this.slideWidth = this.slideTarget.offsetWidth;
    this.slidesToSwipeValue = Math.floor(
      this.containerTarget.offsetWidth / this.slideWidth
    );

    this.currentOffset = 0;

    //The first swipe to selection must be without animation to simulate track of selection
    this.removeAnimationClasses();

    this.moveToSelection();
    this.addEventListeners();
  }

  moveToSelection() {
    if (this.selectedIdValue) {
      const slideIndex = Array.from(this.slideTargets).findIndex(
        (slide) => slide.id === this.selectedIdValue
      );

      // Only move if selectedId slide is not in the first cluster
      if (slideIndex >= this.slidesToSwipeValue) {
        this.slide(
          Math.floor(slideIndex / this.slidesToSwipeValue) *
            this.slidesToSwipeValue *
            this.slideWidth
        );
      }
    }
  }

  nextButtonAction() {
    this.addAnimationClasses();
    this.slide(this.slideWidth * this.slidesToSwipeValue);
  }

  prevButtonAction() {
    this.addAnimationClasses();
    this.slide(-this.slideWidth * this.slidesToSwipeValue);
  }

  slide(offset) {
    this.currentOffset += offset;

    if (this.currentOffset < 0) {
      this.currentOffset = 0;
    }

    this.containerTarget.style.transform = `translateX(${-this
      .currentOffset}px)`;

    this.handleNextButtonVisibility();
    this.handlePrevButtonVisibility();
  }

  handlePrevButtonVisibility() {
    if (this.currentOffset == 0) {
      this.prevTarget.classList.add("hidden");
    } else {
      this.prevTarget.classList.remove("hidden");
    }
  }

  handleNextButtonVisibility() {
    if (
      this.currentOffset + this.slidesToSwipeValue * this.slideWidth >=
      this.slideTargets.length * this.slideWidth
    ) {
      this.nextTarget.classList.add("hidden");
    } else {
      this.nextTarget.classList.remove("hidden");
    }
  }

  addEventListeners() {
    // Event listeners for touch events
    this.element.addEventListener(
      "touchstart",
      this.handleTouchStart.bind(this)
    );
    this.element.addEventListener("touchmove", this.handleTouchMove.bind(this));
    this.element.addEventListener("touchend", this.handleTouchEnd.bind(this));
  }

  handleTouchStart(event) {
    this.touchStartX = event.touches[0].clientX;
  }

  handleTouchMove(event) {
    event.preventDefault();
    if (!this.touchStartX) return;

    const touchDistance = event.touches[0].clientX - this.touchStartX;

    this.removeAnimationClasses();
    this.slide(-touchDistance);

    this.touchStartX = event.touches[0].clientX;
  }

  handleTouchEnd() {
    this.touchStartX = null;
  }

  addAnimationClasses() {
    this.containerTarget.classList.add(
      "transition-transform",
      "duration-300",
      "ease-in-out"
    );
  }

  removeAnimationClasses() {
    this.containerTarget.classList.remove(
      "transition-transform",
      "duration-300",
      "ease-in-out"
    );
  }
}
