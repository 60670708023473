import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["disclaimer"];

  toggleDisclaimer(event) {
    const selectedPaymentMethod = event.target.value;

    if (selectedPaymentMethod === "fonasa_bonus") {
      this.disclaimerTarget.classList.remove("hidden");
    } else {
      this.disclaimerTarget.classList.add("hidden");
    }
  }
}
