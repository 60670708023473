import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static values = {
    email: { type: String, default: "" },
    token: { type: String, default: "" },
  };

  connect() {
    window.hsConversationsSettings = {
      loadImmediately: false,
      ...(this.emailValue && { identificationEmail: this.emailValue }),
      ...(this.tokenValue && { identificationToken: this.tokenValue }),
    };

    if (window.HubSpotConversations) {
      window.HubSpotConversations.widget.load();
    } else {
      window.hsConversationsOnReady = [
        () => {
          window.HubSpotConversations.widget.load();
        },
      ];
    }
  }
}
